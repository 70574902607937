/* eslint-disable max-len */
export const I18nAppTranslationsNL = {
    "app.userNotAuthenticated": "Gebruiker is niet geauthenticeerd",
    "app.no-active-user": "Gebruiker is niet bekend",
    "app.no-active-user-description": "Deze gebruiker is niet bekend in de Bikes Portal. U kunt deze applicatie niet gebruiken.",
    "app.app-title": "Bikes Portal.",
    "app.general": "Algemeen",
    "app.yes": "Ja",
    "app.no": "Nee",
    "app.pdf": "PDF",
    "app.csv": "CSV",
    "app.date-of-update": "Update datum",
    "app.make-a-choice": "Maak een keuze",
    "app.validation.has-value-message": "{{label}} ingevuld.",
    "app.06-molecules.downloadpdfbutton.no-download": "Geen download beschikbaar",
    "app.go-to-link": "Ga naar link",
    "app.home.title": "Welkom,",
    "app.home.description": "U kunt alles doen wat te maken heeft met het beheer van de API's",
    "app.remote-error": "Wij kunnen niet aan uw aanvraag voldoen.",
    "app.fatal-remote-error": "Wij weten niet wat er mis is!",
    "app.remote-init-error": "Geen API beschikbaar.",
    "app.no-id-message-error": "Geen id beschikbaar.",
    "app.remote-init-message":
        "Claude kan geen verbinding met de API maken.",
    "app.notification": "Notificatie",
    "app.warning": "Waarschuwing",
    "app.store.getremoteerrormessage.status": "Status:",
    "app.store.getremoteerrormessage.error": "Foutmelding:",
    "app.not-authorized.title": "Niet geautoriseerd.",
    "app.not-authorized.message":
        "U bent niet geautoriseerd, hierdoor kunnen wij geen verbinding met de API maken, ververs de pagina!",
    "app.disclaimer-title": "Juridische mededeling",
    "app.disclaimer-description": "tbd.",
    // new
    "app.filter": "Zoekfilter",
    "app.continue": "Ga verder",
    "app.cancel": "Annuleer",
    "app.email": "E-mail",
    "app.update": "Wijzig",
    "app.Loading..": "Laden..",
    "app.agree": "Accepteer",
    "app.decline": "Weiger",
    "app.close": "Sluiten",
    "app.step": "Stap",
    "app.summary": "Samenvatting",
    "app.email-provided": "E-mail opgegeven",
    "app.clear": "Wis",
    "app.search": "Zoeken",
    "app.actions": "Acties",
    "app.claude-thinks-something-went-wrong": "Er is iets misgegaan!",
    "app.customerName": "Klantnaam",
    "app.contractNumber": "Contractnummer",
    "app.pickupCode": "Afhaalcode",
    "app.status": "Status",
    "app.invoiceAttached": "Factuur bijgevoegd?",
    "app.financeType": "Soort financiering",
    "app.customerEmail": "Klant e-mail",
    "app.channel": "Kanaal",
    "app.title_codes": "Afhaalcodes overzicht",
    "app.title_codes-add": "Afhaalcode aanmaken (private lease)",
    "app.title_detail_codes": "Afhaalcode overzicht",
    "app.code-provided": "Afhaalcode opgegeven",
    "app.contractNumber-provided": "Contractnummer opgegeven",
    "app.customerName-provided": "Klantnaam opgegeven",
    "app.customerEmail-provided": "Klant e-mail provided",
    "app.channel-provided": "Kanaal opgegeven",
    "app.status-provided": "Status opgegeven",
    "app.financeType-provided": "Soort financiering opgegeven",
    "app.invoiceAmount": "Factuurbedrag",
    "app.createdAt": "Aangemaakt op",
    "app.updatedAt": "Gewijzigd op",
    "app.comment": "Toelichting",
    "app.frameNumber": "Framenummer",
    "app.accuNumber": "Accunummer",
    "app.deliveredBy": "Geleverd door",
    "app.dateOfDelivery": "Datum van aflevering",
    "app.comment-provided": "Toelichting opgegeven",
};
