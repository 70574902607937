/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { lazy } from "react";
import { OrderedSet } from "immutable";

const Codes = lazy(() => import("../App/components/09-views/02-pages/Codes"));
const CreateCode = lazy(() => import("../App/components/09-views/02-pages/CreateCode"));
const CodesDetail = lazy(() => import("../App/components/09-views/02-pages/CodesDetail"));

/**
 *
 */
export type DisplayOf<T extends string | number> = Readonly<Record<T, string>>;

/**
 *
 */
export enum Pages {
    CODES = "CODES",
    ADD_CODE = "ADD_CODE",
}

/**
 *
 */
export enum SubPages {
    DETAIL_CODES = "DETAIL_CODES"
}

/**
 *
 */
export enum PageTabs {
}

export interface Tab {
    title: string;
    description: string;
    roles: string[];
    component?: React.FunctionComponent | React.ComponentState;
}

export interface Page {
    title: string;
    description: string;
    path: string;
    roles: string[];
    component?: React.FunctionComponent | React.ComponentState;
    beta?: boolean;
    pagination?: boolean;
    parent?: Page;
    asMenu?: boolean;
    subpages: OrderedSet<SubPages>;
    tabs?: OrderedSet<PageTabs>;
}

/**
 *
 */
export const PageDisplay: Readonly<Record<Pages, Page>> = {
    [Pages.CODES]: {
        title: "app.title_codes",
        description: "",
        component: Codes,
        path: "/codes",
        roles: [],
        subpages: OrderedSet([SubPages.DETAIL_CODES]),
        tabs: OrderedSet([]),
    },
    [Pages.ADD_CODE]: {
        title: "app.title_codes-add",
        description: "",
        component: CreateCode,
        path: "/codes-add",
        roles: [],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
};

/**
 *
 */
export const SubPageDisplay: Readonly<Record<SubPages, Page>> = {
    [SubPages.DETAIL_CODES]: {
        title: "app.title_detail_codes",
        description: "",
        component: CodesDetail,
        path: "/codes/:id",
        roles: [],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
};
